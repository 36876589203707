<template>
    <transition name="slide-up">
        <div class="content-container content-half" v-if="isActive">
            <v-form ref="form">
                <div class="flex-column">
                    <div class="d-flex">
                        <div class="flex-column padding-div w-100">
                            <div class="fs-32px fw-700 color-dark" >Informe o sexo</div>
                            <div class="n-form-select">
                                <div class="single-input">
                                    <v-select placeholder="Selecione o sexo" class="input-caps" required :rules="[rules.required]" v-bind:items="itemsSexo" v-model="valSexo" item-value="value" item-text="text"></v-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-buttons">
                    <div class="d-flex justify-content-end">
                        <button v-on:click="next" class="button-primary-bg-primary action-button">
                            Avançar
                            <v-icon right>east</v-icon>
                        </button>
                    </div>

                </div>
            </v-form>
        </div>
    </transition>
</template>

<script>
    import 'bootstrap';
    export default {
        name: 's23-sexo',
        props: ['pessoa', 'visible', 'fluxo'],
        data: () => ({
            isActive: false,
            loading: false,
            selection: false,
            step: 1, 
            itemsSexo: [
                { text: 'Masculino', value: "M" },
                { text: 'Feminino', value: "F" }],
            hasErrors: false,
            valSexo:null,
            rules: {
            },
        }),
        watch: {
            visible: function () {
                this.toggle();
            }
        },
        methods: {
            toggle() {
                this.isActive = !this.isActive;

                if (this.isActive) {
                       this.habilitaOpcoes();
                }
            },
            habilitaOpcoes() {
                if (this.pessoa !== null && this.pessoa["perfil-pessoal"] != null && this.pessoa["perfil-pessoal"].sexo != null) {
                    var v = this.pessoa["perfil-pessoal"].sexo;
                    this.valSexo = v;
                } 
            },
            next: function (event) {
                event.preventDefault();

                this.rules = {
                    required: value => !!value || 'Campo obrigatório.',
                };

                this.$nextTick(() => {
                    if (this.$refs.form.validate()) {
                        var v = this.valSexo;
                        this.pessoa["perfil-pessoal"].sexo = v;
                        this.$emit('next');
                    }
                });
            },

            back: function () {
                this.$emit('back');
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

